@font-face {
  font-family: 'CustomCali';
  src: url(./assets/font/PFBodoniScriptOne-Regular.otf);
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CustomCali';
  src: url(./assets/font/PFBodoniScriptOne-Light.otf);
  font-weight: lighter;
  font-display: swap;
}

@font-face {
  font-family: 'CustomCali';
  src: url(./assets/font/PFBodoniScriptOne-Bold.otf);
  font-weight: bold;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cu-d-none {
  display: none;
}

.cu-top-index {
  z-index: 1000;
}

.cu-font {
  font-family: 'CustomCali';
}

.cu-animation  {
  animation-timing-function: linear;  
  transition: all ease-in-out 0.5s;
}

.gray-scale {
  filter: grayscale(1);
}