
body, html {
  font-family: 'Open Sans', sans-serif;
}

.font-cali {
  font-family: 'CustomCali';
}

.book-link {
  width: 240px;
}

.text-md {
  font-size: 1.2rem;
  line-height: 1.2;
}

.cu-dropdown-full {
  left: 0 !important;
  margin: 0 !important;
  transition: all 300ms ease-in-out;
  z-index: -1;
  background-color: #f8f8f8;
  max-height: 85vh;
  overflow-y: scroll;
}

.cu-cover-full {
  width: 100%;
  height: 700px;
  max-height: 85vh;
}

.cu-page {
  margin-top: -50px;

  height: 550px;
  width: 600px;
}

.cu-page-l {
  margin-top: -50px;

  height: 550px;
  width: 400px;
}

.book-cover-landing {
  /* margin-top: -25px;
  height: 600px;
  width: 450px; */
  padding: 5px;
  background-color: white;
  /* margin-left: -50px; */
}

.book-landing-heading {
  font-family: 'CustomCali';
  font-weight: bold;
  font-style: italic;
  font-size: 32px;
}

.cu-custom-transition {
  transition: all ease-in-out 0.3s;
}

.uk-custom-width {
  width: 85%;
}

.cu-tab { text-indent:40px }

.cu-book-images {
  width: 25%;
  height: 225px;
}

.cu-book-images:hover {
  cursor: pointer;
}

.cu-hover:hover {
  cursor: pointer;
}

.nav-color-span > div > ul > li > a > span{
  color: wheat;
}

.cu-smooth-transition {
  transition: 'all 200ms ease-in-out';
}

.playlist-title {
  font-family: 'CustomCali';
  font-weight: bolder;
  font-size: 30px;
  letter-spacing: 1.2px;
  color: black;
}

textarea[name=contact] {
  resize: none;
}

/* Menu Grid */

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: auto;
  gap: 10px;
  max-width: 100%;
  max-height: 400px; 
  overflow-y: auto;
  padding: 10px;
}

.image-item {
  max-width: 100%;
  overflow: hidden;
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}
